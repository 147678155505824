/* body {
    --container-background-color: #C7C7C7;
  }
 */
/* 
 .input-scrollbar::-webkit-scrollbar-track {
	background-color: rgb(255, 255, 255);
	-webkit-border-radius: 1px;
}
.input-scrollbar::-webkit-scrollbar-thumb:vertical {
	background-color: rgb(142, 142, 142);
	-webkit-border-radius: 0px;
    -webkit-width:5;
} */
/* 

.input-scrollbar::-webkit-scrollbar-thumb {
   background-color: grey;
   border-radius: 10px;
   scrollbar-width: 20px;
}

.input-scrollbar {
 overflow: auto;
 max-height: 500px;
 min-height: 100px;
 width: 200px;
}

.input-scrollbar div {
 max-height: 50px;
}   */