.container {
  display: flex;
  flex-direction: column;
  height: calc(100% + 20px);
  margin: 0 5px;
  padding: 2px 7px;
  position: relative;
  z-index: 101;
}

.container::after, .container::before {
  background: #5F6368;
  content: '';
  height: 100%;
  opacity: 0.4;
  position: absolute;
  top: 0px;
  width: 1px;;
}

.container span {
  margin-right: 5px;
}

.container::after {
  left: 0;
}

.container::before {
  right: 0;
}

.selectRow {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
}

.time {
  align-items: center;
  display: flex;
  flex-direction: column;
  /* padding-top: 2px; */
}

.time :last-child {
  letter-spacing: 2px;
  line-height: 1;
}
.time :first-child {
  letter-spacing: 1.5px;
}